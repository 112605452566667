$(document).ready(function() {

    !function () {
        $('.h-menu__close').on('click', function () {
          $('.h-menu').removeClass('h-menu--active');
        });
        $('.header-top__menuopen').on('click', function () {
          $('.h-menu').addClass('h-menu--active');
        });
    }();

    $('.h-menu__item--submenu .h-menu__link').on("click", function() {
        if ($(this).hasClass("active")) {
            $(this).siblings('.submenu').slideUp("slow");
            $(this).removeClass("active");
        } else {
            $(this).siblings('.submenu').slideDown("slow");
            $(this).addClass("active");
        }
    });

    var $slider = $('.main-slider');

    if ($slider.length) {
        var currentSlide;
        var slidesCount;
        var updateSliderCounter = function(slick, currentIndex) {
            currentSlide = slick.slickCurrentSlide() + 1;
            slidesCount = slick.slideCount;
            $slider.find('.slider__counter .current').text('0' +currentSlide);
            $slider.find('.slider__counter .slush').text('/');
            $slider.find('.slider__counter .all').text('0' +slidesCount);
        };

        $slider.on('init', function(event, slick) {
            $slider.append('<div class="slider__counter container"><span class="current"></span><span class="slush"></span><span class="all"></span></div>');
            updateSliderCounter(slick);
        });

        $slider.on('afterChange', function(event, slick, currentSlide) {
            updateSliderCounter(slick, currentSlide);
        });

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            autoplay: false,
            fade: true,
            cssEase: 'linear',
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>',
        });
        
    }

    $("#fl_inp").change(function () {
        var filename = $(this).val().replace(/.*\\/, "");
        $("#fl_nm").html(filename);
    });
    $(function () {
        $("#phone1, #phone2, #phone3, #phone4").mask("+7(999) 999-99-99");
    });

    if ($('body').width() < 400) {
        $('.header-bottom__time br').remove();
        $('.header-bottom__address br').remove();
    }

    $('.product-types-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        autoplay: false,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 751,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.product-photo-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.product-photos-slider-nav'
    });
    $('.product-photos-slider-nav').slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        asNavFor: '.product-photo-slider',
        vertical: true,
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 1121,
                settings: {
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 951,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 801,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 701,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 5,
                    vertical: false
                }
            },
            {
                breakpoint: 401,
                settings: {
                    slidesToShow: 4,
                    vertical: false
                }
            },
            {
                breakpoint: 321,
                settings: {
                    slidesToShow: 3,
                    vertical: false
                }
            }
        ]
    });
    $('.sertif-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        autoplay: false,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 701,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    $('.popup-gallery, .popup-gallery1, .popup-gallery2, .popup-gallery3, .popup-gallery4, .popup-gallery5').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        fixedContentPos: true,
        showCloseBtn: false,
        gallery: {
          enabled: true,
          navigateByImgClick: false,
          preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    
        }
    });

    $('.portfolio-main-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.portfolio-main-slider-nav'
    });
    $('.portfolio-main-slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.portfolio-main-slider',
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 841,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 701,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 481,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });


    var $slider1 = $('.examples-slider-nav');

    if ($slider1.length) {
        var currentSlide;
        var slidesCount;
        var updateSliderCounter = function(slick, currentIndex) {
            currentSlide = slick.slickCurrentSlide() + 1;
            slidesCount = slick.slideCount;
            $slider1.find('.slider__counter .current').text('' +currentSlide);
            $slider1.find('.slider__counter .slush').text('/');
            $slider1.find('.slider__counter .all').text('' +slidesCount);
        };

        $slider1.on('init', function(event, slick) {
            $slider1.append('<div class="slider__counter container"><span class="current"></span><span class="slush"></span><span class="all"></span></div>');
            updateSliderCounter(slick);
        });

        $slider1.on('afterChange', function(event, slick, currentSlide) {
            updateSliderCounter(slick, currentSlide);
        });

        $('.examples-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.examples-slider-nav'
        });
        $slider1.slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            asNavFor: '.examples-slider',
            vertical: true,
            dots: false,
            arrows: false,
            centerMode: false,
            focusOnSelect: true,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1121,
                    settings: {
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 1001,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 841,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 801,
                    settings: {
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 701,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 651,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 551,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 5,
                        vertical: false
                    }
                },
                {
                    breakpoint: 421,
                    settings: {
                        slidesToShow: 4,
                        vertical: false
                    }
                },
                {
                    breakpoint: 321,
                    settings: {
                        slidesToShow: 3,
                        vertical: false
                    }
                }
            ]
        });
        
    }

    (function($) {
        $(function() {
        $("ul.tabs__caption").on("click", "li:not(.active)", function() {
                $(this)
                .addClass("active")
                .siblings()
                .removeClass("active")
                .closest("div.tabs")
                .find("div.tabs__content")
                .removeClass("active")
                .eq($(this).index())
                .addClass("active");
            });
        });
    })(jQuery);

    $(".per-height__input input").focusin(function() {
        $(this).closest('.per-height').find('svg').css('fill','#2A8EAF');
    });
    $(".per-height__input input").focusout(function() {
        $(this).closest('.per-height').find('svg').css('fill','#DBDBDB');
    });

    $(".per-length__input input").focusin(function() {
        $(this).closest('.per-length').find('svg').css('fill','#2A8EAF');
    });

    $(".per-length__input input").focusout(function() {
        $(this).closest('.per-length').find('svg').css('fill','#DBDBDB');
    });

    /*высота сантехничсеких*/
    $(".per-height__input input").focusin(function() {
        $(this).closest('.per-height').siblings('#santeh').find('svg').find('path.arrow').css('fill','#2A8EAF');
    });
    $(".per-height__input input").focusout(function() {
        $(this).closest('.per-height').siblings('#santeh').find('svg').find('path.arrow').css('fill','#AEAEAE');
    });

});